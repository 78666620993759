import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "src/components/core/layout/masonry.css"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import "src/components/photos/lightbox.scss"
import SEO from 'src/components/seo/SEO';

export const query = graphql`
  query VideoQuery {
    allPrismicVideo {
      nodes {
        data {
          date(difference: "now")
          title {
            text
          }
          description {
            text
          }
          cover {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
          video {
            embed_url
          }
        }
        id
      }
    }
  }
`

export default ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 3,
    500: 2,
  }

  const videos = []
  data.allPrismicVideo.nodes.forEach(video =>
    videos.push(
      <PhotoTile
        key={video.id}
        photo={video.data.cover ? video.data.cover.fluid.src : null}
        name={video.data.title ? video.data.title.text : null}
        project={video.data.description ? video.data.description.text : null}
        link="modal"
        type="video"
        videourl={video.data.video ? video.data.video.embed_url : null}
      />
    )
  )

  return (
      <>
      <SEO title="Video"/>
    <Layout>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {videos}
      </Masonry>
    </Layout>
    </>
  )
}
